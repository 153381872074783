.header-container {
  justify-content: space-between;
}

.antd-ghost-btn-green {
  color: #28a745 !important;
  border-color: #28a745 !important;
  border-radius: 5px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 16px !important;
}

.chart-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding-right: 25px;
}

.is-select-to-delete {
  background-color: #ffd3d3;
  border: 1px solid #dc3545;
}

.table-head {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-head-2 {
  display: flex;
  align-items: center;
}

.antd-ghost-btn {
  border-radius: 5px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 16px !important;
}

.modalstyle-dc .ant-modal-content {
  /* width: 650px; */
  border-radius: 10px !important;
}

.ant-btn {
  border-radius: 5px !important;
}

.ant-input {
  border-radius: 5px !important;
}
