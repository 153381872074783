.table-head-report {
  font-weight: bold;
  display: grid;
  grid-template-columns: 150px 180px 250px 150px 200px 120px 300px 150px 150px 150px 150px 200px 100px 150px 150px 150px 150px;

  position: sticky;
  top: 0;
  z-index: 99999;
}

.approve-btn {
  content: "";
  border-radius: 5px;
  transition: none;
}

.approve-btn:hover {
  border-radius: 25px;
  background: red !important;
}

.approve-btn:hover .approved-text {
  display: none;
}

.approve-btn .cancel-text {
  display: none;
}

.approve-btn:hover .cancel-text {
  display: block;
}

.table-content {
  background-color: #c9cdd4;
  height: 45px;
  font-size: 16px;
  align-items: center;
  display: flex;
}

.delete-or-cancel-container {
  position: absolute;
  display: flex;
  gap: 20px;
  bottom: 0px;
  padding: 10px;
  color: red;
  font-weight: bold;
}

.table-head-sub {
  display: flex;
  width: 2850px;
}

.main-table-head-sub {
  background-color: #e5f4ff;
  border: 2px solid #e5e4e5;
  border-radius: 5px;
  margin: 7px 0;
  width: 2850px;
  position: relative;
  min-height: 80px;
}

.ant-switch.ant-switch-checked {
  background-color: #0d8d21;
}

.modal-box {
  padding: 0 0 0 10px;
  height: 32px;
  background: #99d4ff;
  align-items: center;
  display: flex;
  cursor: pointer;
}

span.ant-radio + * {
  width: 100%;
}

.ant-modal {
  /* width: unset !important; */
}

.modalstyle .ant-modal-content {
  width: 900px;
}

/* Designing for scroll-bar */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: gainsboro;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-select-single .ant-select-selector {
  border-radius: 5px !important;
}

.table-content-sub {
  border-bottom: 2px solid #c9cdd4;
  border-right: 2px solid #c9cdd4;
  padding: 5px;
  padding-right: 10px;
  gap: 5px;
  /* height: 45px; */
  /* margin: 5px 0; */
  /* margin-top: 10px; */
  font-size: 16px;
  /* align-items: center; */
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.table-child {
  margin: 0;
  height: 30px;
  align-items: center;

  /* margin-bottom: 10px; */
  display: flex;
  /* justify-content: center; */
}

.container-table {
  width: 100%;
  overflow-y: auto;
}

.content-table {
  transform: rotateX(180deg);

  height: 65vh;
  overflow: scroll;
}

.Flipped,
.Flipped .container-table {
  transform: rotateX(180deg);
  -ms-transform: rotateX(180deg); /* IE 9 */
  -webkit-transform: rotateX(180deg); /* Safari and Chrome */
}
