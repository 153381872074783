.table thead th {
  border-bottom: 2px solid white !important;
}
.table-bordered td, .table-bordered th {
  border: 1px solid #f0f8ff !important;
}

.table tbody {
  color: #4a4a4a;
}

td.td-info {
  cursor: pointer;
}
td.td-info:hover {
  color: black;
  background-color: rgb(235, 235, 235);
  z-index: 1;
}
td.td-info:hover i {
  filter: invert(0.5)
}
.report-tooltip-wrapper {
  position: relative;
}
.report-tooltip {
  width: 500px;
  position: absolute;
  background: white;
  z-index: 100;
  border-radius: 2px;
  padding: 5px;
  margin-top: 6px;
}

.report-tooltip .row .header {
  background-color: aliceblue;
  border-bottom: 1px solid var(--lightgrayblue);
  font-weight: bold;
}

.report-tooltip-triangle {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid white;
  z-index: 90;
  position: absolute;
  margin-left: 7px;
}