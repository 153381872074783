.card-box {
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background-color: var(--bg_card);
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein 1s; /* Firefox < 16 */
      -ms-animation: fadein 1s; /* Internet Explorer */
       -o-animation: fadein 1s; /* Opera < 12.1 */
          animation: fadein 1s;
}
.card-box.no-round {
  border-radius: 0px;
}

.card-box.light {
  background-color: var(--bg_card_light);
}

.card-box.info {
  background-color: var(--bg_card_info);
  border: unset;
  padding: 10px;
}

.card-icon-link {
  cursor: pointer;
  padding: 5px;
  width: 35px;
  height: 36px;
  border-radius: 50%;
  background-color: rgb(30, 134, 253);
}

.outer-card, .outer-card-md {
  padding: 15px;
  border-radius: 10px;
  /* background-color: rgb(158 168 169 / 20%); */
  background-color: rgb(231 242 251 / 50%);
  margin-top: 20px;
  border: 1px solid #dee2e6;
}

.home-scrollable {
  /* max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none; */  /* IE and Edge */
  /* scrollbar-width: none; */  /* Firefox */
}


.block-info {
  margin-top: 10px;
  /* max-height: 80vh;
  overflow-y: auto; */
  /* overflow-x: hidden; */
}

.block-info-buffer-box {
  height: 15vh;
}

.line {
  width: 100%;
  border-bottom: 1px solid rgb(204, 204, 204);
}

.home-scrollable::-webkit-scrollbar {
  display: none;
}

.sticky-header, .sticky-header-btn  {
  position: -webkit-sticky;
  position: sticky;
  z-index: 10;
  top: 0;
}

.sticky-header-btn {
  z-index: 100;
  top: 10px;
}

.position-fixed-botton {
  position: fixed;
  bottom: 5px;
}

.overlay-box {
  position: fixed;
  inset: 0px;
  background-color: #c3c3c394;
  z-index: 900;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-hide {
  display: none;
}

.btn-big-round {
  height: 100px;
  width: 100px;
  border-radius: 50% !important;
  font-size: 50px !important;
}

/************ ToggleSwitch **************/
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 28px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dashboard_installation {
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: bold;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-link {
  color: black;

}
.custom-link:hover {
  text-decoration: none;
  color:black;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--bootstrap_green);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--bootstrap_green);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 27px;
}

.slider.round:before {
  border-radius: 50%;
}
/************ ToggleSwitch **************/

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .outer-card {
    border-radius: 0px;
  }
  .outer-card-md {
    background-color: transparent;
    margin-top: 0px;
    border: none
  }

  .sticky-header-md {
    position: -webkit-sticky;
    position: sticky !important;
    top: 0;
  }
}