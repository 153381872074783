.order-item-center {
  display: flex;
  align-items: center;
}

.order-item-w-12 {
  display: flex;
  align-items: center;
  width: 12%;
}

.order-item-w-14 {
  display: flex;
  align-items: center;
  width: 14%;
}

.order-item-w-16 {
  display: flex;
  align-items: center;
  width: 16%;
}

.order-item-break-word {
  width: 100%;
  word-wrap: break-word;
}

.items-modal-container {
  display: grid;
  grid-template-columns: 20% 20% 40% 20%;
  text-align: center;
  align-items: center;

  margin-bottom: 10px;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.repair-header {
  display: grid;
  grid-template-columns: 5% 18% 10% 10% 9% 15% 15% 15%;
  background-color: white;
  gap: 5px;
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .repair-header {
    display: none;
  }

 
}