:root {
  --lightblue01: #B6E9FF;
  --lightblue02: #E8F8FF;
  --lightblue03: #f0f8ff;
  --lightgray01: #e6e6e6;
  --lightgray05: #dcdcdc;
  --lightgray04: #cfcfcf;
  --lightgray02: #afafaf;
  --lightgray03: #858585;
  --lightgray_opt1: #f6f6f680;
  --lightgray_opt2: #b4b6b980;
  --darkblue: #0F205E;
  --darkgrayblue: #5b6b90;
  --lightgrayblue: #8dacd47a;
  --lightgrayblue02: #5274a27a;
  --lightred: #ffb3b37a;
  --lightyellow01: #fff0cf9c;
  --bg_card: #ffffffd9;
  --bg_card_light: #e7f2fb80;
  --bg_card_info: #ffd660;
  --grayyellow: #f1eca1b6;
  --green: #00c12b;
  --bootstrap_green: #28a745;
  --darkgray01: #4d4d4d;
  --white_opt1: #ffffff80;

  /* select item on browsing pages */
  --index-on-hover: #fffff1;
  /* Amplify */
  --amplify-primary-color: #65c6f0;
}

.p-t-100 {
  padding-top: 100px !important;
}