
.card-box .order-data-collapse {
  font-size: 12px;
  max-height: 0px;
  /* transition: max-height 700ms linear; */
  overflow-y: scroll;
  overflow-x: hidden;
  border-top: 1px dashed #e3dfdfab;
  padding-top: 5px
  /* background-color: #fffce9; */
}

.card-box .order-data-collapse.show {
  /* height: 200px; */
  max-height: 250px;
  height: auto;
  /* background-color: var(--index-on-hover) !important; */
}

.card-box .order-data-collapse:hover {
  /* background-color: white; */
  background-color: var(--index-on-hover) !important;
}
/* 
.link-no-hover.order:hover .order-data-collapse {
  background-color: var(--index-on-hover) !important;
  max-height: 250px;
  height: auto;
} */

.order-manu-header {
  border-radius: 5px;
  font-weight: bold;
  background-color: var(--lightgrayblue);
  border-bottom: 1px solid var(--darkblue);
  cursor: pointer;
}

.order-item-header {
  border-radius: 2px;
  background-color: #ebebebab;
  cursor: pointer;
}

.items-name {
  font-weight: bold;
}

.checkmark-wrapper {
  /* color: white;
  background: var(--lightgray02); */
  color: var(--lightgray02);
  border: 1px solid var(--lightgray02);
  border-radius: 5px;
  padding: 0px 3px;
  margin-left: 8px;
  font-weight: bold;
}

.checkmark-wrapper.active {
  /* background: var(--bootstrap_green); */
  color: var(--bootstrap_green);
  border: 1px solid var(--bootstrap_green);
}

.order-preview-import {
  max-height: 300px;
  overflow-y: auto;
}
.order-preview-import-header {
  padding: 5px 5px 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  background-color: #cedff47a;
  border-bottom: 1px solid var(--darkblue);
}

.order-import-basket-wrapper {
  position: fixed;
  bottom: 10px;
  right: 30px;
}
.order-import-basket-wrapper .count-nb {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: red;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  font-weight: bold;
  padding-top: 2px;
}
.order-modal-basket {
  display: flex;
  flex-direction: column;
}
.order-modal-basket .order-list {
  overflow-y: auto;
  overflow-x: hidden;
  height: 75vh;
}


.card-flex{
  display: flex;
  flex-direction: row;

}

.card-in-menu{
  background-color: var(--lightgrayblue);
  border-bottom: 1px solid var(--darkblue);
  cursor: pointer;
  border-radius: 5px;
  margin-right: -15px;
  margin-left: -15px; 
  align-items: center; 

}

.title-in-card{
  text-align: left; 
}

.card-width
{
  width: 50%;
}

.box-btn{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 10px;
}

.box-input{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.card-margin{
  margin-left: 3rem;
  margin-right: 3rem;
  margin-top:20px;
} 



@media only screen and (max-width: 600px) {
  .card-flex{
    flex-direction: column;
  }
  .card-width
  {
    width: 100%;
  }
  .box-btn{
    justify-content: center;
  }
  .box-input{
    padding: 15px;
    justify-content: center;
  }

  .title-in-card{
    text-align: center; 
  }

  .card-margin{
    margin-left: 0;
    margin-right: 0;
    margin-top:20px;
  } 
  

}
