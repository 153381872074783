/* Large devices */
@media (max-width: 1199px) {
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
}

/* Extra small */
@media (max-width: 576px) {
}

.text-bold {
  font-weight: bold;
}

.approved-container {
  display: flex;
  cursor: pointer;
  transition: 0.25s;
}

.main-type {
  color: #007bff;
}

/* sm */
@media (max-width: 575px) {
  .collapse-content-price {
    display: grid;
    grid-template-columns: 100px 50px 25px;
  }

  .collapse-content {
    display: grid;
    grid-template-columns: 100px 150px auto;
  }

  .collapse-item-content {
    max-width: 150px;
    display: flex;
    gap: 10px;
  }
}

/* md */
@media (min-width: 576px) {
  .collapse-content-price {
    display: grid;
    grid-template-columns: 200px 100px 50px;
  }

  .collapse-content {
    display: grid;
    grid-template-columns: 200px 300px auto;
  }

  .collapse-item-content {
    max-width: 300px;
    display: flex;
    gap: 20px;
  }
}

.approved-text {
  display: flex;
}

.cancel-text {
  display: none;
}

.approved-container:hover .approved-text {
  display: none;
}

.approved-container:hover .cancel-text {
  display: block;
}

.approved-container:hover {
  background-color: red !important;
  transition: 0.25s;
}

.dc-tabs-container {
  padding: 20px 80px;
}

.text-header {
  font-size: 32px;
  font-weight: bold;
}

.detail-content-header-table {
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background-color: var(--bg_card);
  animation: fadein 1s;
  min-height: 40px;
  padding: 10px;
  margin-bottom: 10px;
}

.expense-text {
  text-align: right;
}

.input-content {
  display: grid;
  margin-top: 10px;
  grid-template-columns: 30% 70%;
  gap: 20px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
}

.note-container {
  border: 1px solid #80808070;
  border-radius: 10px;
  width: 100%;
  display: grid;
  grid-template-columns: 5% 95%;
  font-weight: bold;
  font-size: 14px;
  gap: 10px;
  background-color: var(--bg_card);
}

.delete-expensive {
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.expense-total {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: right;
  position: relative;
}

.detail-content-table {
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background-color: var(--bg_card);
  animation: fadein 1s;
  padding: 10px 0 0 0;
  margin-bottom: 10px;
}

.detail-adjust-content {
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background-color: var(--bg_card);
  animation: fadein 1s;
  min-height: 40px;
  padding: 20px 40px;
}

.detail-adjust-content-contract {
  border: 1px solid #e8e8e8;
  background-color: rgba(255, 0, 0, 0.2);
  animation: fadein 1s;
  min-height: 40px;
  padding: 0 10px;
}

.ant-select.ant-select-single.ant-select-show-arrow > div {
  height: 45px;
  align-items: center;
}

.ant-select-selection-item {
  font-size: 16px;
  font-weight: bold !important;
}

.ant-input {
  height: 40px;
  border-radius: 5px;
}

.detail-content {
  gap: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background-color: var(--bg_card);
  animation: fadein 1s;
  min-height: 100px;
  padding: 20px 40px;
}

.approve-text {
  width: 250px;
}

.antd-datepicker-custom {
  height: 40px;
  border-radius: 5px !important;
}

.detail-sub-content {
  display: grid;
  grid-template-columns: 50% 50%;
}

.sub-content-text {
  width: 150px;
}

.sub-content-warper {
  display: flex;
  align-items: center;
}

.title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.div-flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.div-flex-row-start {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.add-modal-header-expense-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.category-name-select-content {
  display: grid;
  align-items: center;
  grid-template-columns: 70px auto;
}

.qty-discount-input {
  width: 100px !important;
  margin: 0px 0px 0px -20px !important;
  border-radius: 5px !important;
}

.item-name-select-content {
  display: grid;
  align-items: center;
  grid-template-columns: 70px 170px auto;
  transition: 0.25s;
  border-radius: 5px;
  border: 1px solid #e8e8e800;
  cursor: pointer;
}

.item-name-select-content:hover {
  background-color: #f1eca1b6;
  border: 1px solid #e8e8e8;
  transition: 0.25s;
}

.expand-modal-container .ant-modal {
  width: 90% !important;
  height: 90% !important;
}

.item-modal-content {
  height: 78vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.add-expense-item-content {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.expand-modal-container .ant-modal-content {
  height: 100% !important;
}

.header-expan-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-expan-container {
  font-size: 22px;
  font-weight: bold;
}

.card-box .order-data-collapse {
  display: flex;
  flex-direction: column;
  max-height: 0px;
  font-size: smaller;
  overflow-y: scroll;
  overflow-x: hidden;
  border-top: 1px dashed #e3dfdfab;
  padding-top: 5px;
  color: var(--lightgray03);
}

.card-box .order-data-collapse.show {
  padding-bottom: 15px;
  max-height: 250px;
  height: auto;
}

.card-box .order-data-collapse:hover {
  background-color: var(--index-on-hover) !important;
}

.header-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-title-text {
  font-size: 24px;
  /* font-weight: bold; */
}

.table-text-right {
  text-align: right;
}

.complete-title {
  display: flex;
  gap: 10px;
  font-size: 14px;
  font-weight: bold;
}

.red-mark {
  color: red;
  font-weight: bold;
}

.unit-with-input {
  white-space: nowarp;
}

.qty-add-expense-input {
  width: 100% !important;
  /* text-align: left !important; */
  min-width: 50px !important;
  border-radius: 5px !important;
  min-height: 40px;
}

.qty-add-expense-input > div > input {
  text-align: left !important;
}

.qty-input {
  width: 40% !important;
  text-align: right !important;
  min-width: 70px !important;
  border-radius: 5px !important;
  min-height: 40px;
}

.qty-input-add-item {
  width: 100% !important;
  text-align: left !important;
  min-width: 50px !important;
  border-radius: 5px !important;
  min-height: 40px;
}

.qty-input-add-item > div > input {
  text-align: left !important;
}

.item-container-header {
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background-color: var(--bg_card);
  animation: fadein 1s;
  height: 40px;
  display: grid;
  grid-template-columns: 5% 45% 20% 15% 15%;
  align-items: center;
  padding: 0 20px 0px 30px;
}

.table-container-header {
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background-color: var(--bg_card);
  animation: fadein 1s;
  height: 40px;
  display: grid;
  grid-template-columns: 5% 45% 20% 15% 15%;
  align-items: center;
  padding: 0 20px;
  font-weight: bold;
}

.categoty-price {
  font-weight: bold;
  margin-right: 20px;
}

.sigh-bath::after {
  padding-left: 3px;
  content: "\E3F";
}

.main-categoty-container {
  margin-top: 10px;
  border-radius: 5px;
  background-color: var(--lightred);
  animation: fadein 1s;
  height: 40px;
  display: grid;
  grid-template-columns: 5% 60% 17.5% 17.5%;
  gap: 5px;
  align-items: center;
  padding: 0 20px;
  font-weight: bold;
}

.adjust-container {
  display: grid;
  grid-template-columns: 75% 25%;
}

.alert-not-match {
  position: absolute;
  border: 2px solid red;
  padding: 5;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
  right: -35px;
  z-index: 99;
}

.ant-input-number-input {
  height: 37px !important;
  text-align: right !important;
}

.empty-content {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
}

.expand-modal-item {
  width: 25px;
  height: 25px;
  border-radius: 5px;
  background-color: #007bff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mt {
  margin-top: 10px;
}

.add-expense-footer-content {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 5px;
}

.add-expense-btn {
  background-color: #28a745 !important;
  border-radius: 5px;
  height: 40px !important;
  margin-top: 10px;
  color: white !important;
  border-color: #28a745;
}

.cancel-expense-btn {
  background-color: #6c757d !important;
  border-radius: 5px;
  height: 40px !important;
  margin-top: 10px;
  color: white !important;
  border-color: #6c757d;
}

.add-item-container {
  padding: 0px 0px 0px 10px;
}

.summary-content {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: space-between;
  align-items: right;
  padding: 10px 10px 0 10px;
  font-size: 16px;
  font-weight: bold;
}

.contract-option {
  /* display: flex; */
  gap: 5px;
  align-items: center;
  display: grid;
  grid-template-columns: 35% 65%;
  /* justify-content: center; */
}

.summary-content-result {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: space-between;
  align-items: right;
  padding: 10px 10px 10px 10px;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  background-color: var(--grayyellow);
}

.add-btn {
  margin-top: 10px;
  color: white;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
  color: white !important;
  font-weight: bold;
  height: 40px;
}

.add-container {
  text-align: right;
}

.po-container {
  animation: fadeIn 1s;
  padding: 24px 24px !important;
}

.qty-container {
  display: grid;
  grid-template-columns: 85% 15%;
  gap: 20px;
  align-items: center;
}

.auto-create-mr-container {
  background-color: var(--bg_card_info);
  /* padding: 10px 20px 10px 15px; */
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.table-item-name {
  background-color: #c3ddf4;
  height: 40px;
  width: 100%;
  padding-left: 10px;
  font-weight: bold;
  align-items: center;
  display: flex;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.table-item-icon {
  background-color: #8eb2d1;
  height: 40px;
  width: 40px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.table-item {
  display: flex;
  align-items: center;
  margin-top: 10px;
  /* gap: 10px; */
}

.table-header-container {
  border: 1px solid #808080;
  background-color: #f5f5f5;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 60px;
  display: grid;
  font-weight: bold;
  grid-template-columns: 10% 20% 10% 10% 12% 15% 13% 10%;
}

.table-item-content {
  border: 1px solid #808080;
  background-color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 10px;
  display: grid;
  font-weight: bold;
  align-items: center;
  grid-template-columns: 10% 20% 10% 10% 12% 15% 13% 10%;
}

.edit-icon {
  background-color: #007bff;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
}

.edit-icon-is-edit {
  background-color: #28a745;
}

.recived-container {
  animation: fadeIn 1s;
}

.remove-icon {
  cursor: pointer;
  background-color: #dc3545;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.remove-icon-none-id {
  cursor: pointer;
  border: 1px solid red;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
}

.edit-container {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: right;
  font-size: 16px;
}

.header-revice-container {
  background-color: var(--bg_card);
  border: 1px solid #e8e8e8;
  padding: 20px 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.add-po-container {
  background-color: var(--bg_card);
  border: 1px solid #e8e8e8;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.remark-container {
  margin-top: 10px;
}

.po-warper-footer {
  text-align: right;
}

.detete-btn {
  height: 40px;
  /* width: 60px; */
  background-color: red !important;
  color: white !important;
}

.require-mark {
  color: red;
  font-weight: bold;
}

.po-date-picker {
  font-weight: normal !important;
  height: 40px !important;
  border-radius: 5px !important;
  width: 100% !important;
}

.po-warper {
  display: grid;
  grid-template-columns: 20% 80%;
  text-align: left;
  gap: 10px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.po-left-content {
  text-align: left;
}

.po-content {
  display: grid;
  grid-template-columns: 47% 6% 47%;
  /* gap: 10px; */
  /* column-gap: 20px; */
  /* gap: 40px; */
}

.mr-info {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: bold;
}

.text-right {
  align-items: right;
}

.add-item-warper {
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background-color: var(--bg_card);
}

.add-item-warper-res {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  width: 80%;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background-color: white;
}

@media only screen and (max-width: 991px) {
  .add-item-warper-res {
    display: block;
  }
}

.item-detail-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 10px 10px 10px;
}

.add-item-button {
  height: 40px !important;
}

.btn-cancel {
  background: gray !important;
  color: white !important;
  border: none !important;
}

.bg-aliceblue {
  background-color: aliceblue !important;
}

.add-item-content {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding: 10px 10px 10px 10px;
  font-size: 14px;
  font-weight: bold;
}

.table-container-items {
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background-color: var(--bg_card);
  align-items: center;
}

.table-text-center {
  margin-left: 12px;
}

.table-content-items {
  padding: 0 20px;
  height: 40px;
  display: grid;
  grid-template-columns: 5% 45% 20% 15% 15%;
  align-items: center;
}

.approve-title {
  display: grid;
  grid-template-columns: 55% 45%;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
}

.header-title-approve {
  display: flex;
  gap: 30px;
}

.item-summary-list-container {
  animation: fadein 1s;
}

.item-inform-container {
  animation: fadein 1s;
}

.inform-table-header-container {
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background-color: var(--bg_card);
  align-items: center;
  padding: 0 20px;
  height: 40px;
}

.inform-table-header-content {
  display: grid;
  height: 40px;
  align-items: center;
  grid-template-columns: 5% 95%;
}

.tab-disable {
  color: gray !important;
  cursor: not-allowed !important;
}

.disabled-btn {
  color: white !important;
  background-color: gray !important;
  border: none !important;
}

.inform-table-item-header {
  border-radius: 5px;
  font-weight: bold;
  background-color: var(--lightgrayblue);
  border-bottom: 1px solid var(--darkblue);
  cursor: pointer;
  padding: 10px 20px;
  display: grid;
  grid-template-columns: 5% 70% 25%;
  align-items: center;
  margin-top: 20px;
}

.inform-vendor {
  display: flex;
  justify-content: right;
  white-space: nowrap;
  gap: 5;
  align-items: center;
  gap: 5px;
}

@media screen and (max-width: 991px) {
  .inform-table-item-header {
    border-radius: 5px;
    font-weight: bold;
    background-color: var(--lightgrayblue);
    border-bottom: 1px solid var(--darkblue);
    cursor: pointer;
    padding: 10px 20px;
    display: block; 
    align-items: center;
    margin-top: 20px;
  }
  .inform-vendor {
    display: flex;
    justify-content: left;
    white-space: nowrap;
    gap: 5;
    align-items: center;
    gap: 5px;
    margin-top:5px;
    margin-left: 40px;
  }
}



.inform-date-pickup {
  position: relative !important;
  z-index: 100 !important;
  border-radius: 5px !important;
  height: 40px;
}

.inform-text {
  text-align: right;
}

.code-text-center {
  width: 180px;
  text-align: center;
}

.expan-product-detail-container {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  background-color: var(--bg_card);
  animation: fadein 1s;
  overflow: hidden;
  transition: 0.25s;
}

.inform-product-collapse {
  max-height: 0px;
  transition: 0.25s;
  border-bottom: none !important;
  border-top: none !important;
  overflow: hidden !important;
}

.inform-product-expaned {
  max-height: 1000px;
  transition: 0.25s;
  overflow: hidden !important;
}

.icon-expaned {
  transform: rotate(0deg);
  transition: 0.25s;
}

.icon-collapse {
  transform: rotate(-180deg);
  transition: 0.25s;
}

.expan-product-code-header {
  display: grid;
  height: 40px;
  align-items: center;
  grid-template-columns: 20% 30%;
  gap: 20px;
  padding: 0 20px;
  background-color: #ebebebab;
}

.expan-product-code-item-header {
  display: grid;
  /* height: 40px; */
  align-items: center;
  grid-template-columns: 20% 30%;
  gap: 20px;
  padding: 10px 20px;
  background-color: var(--bg_card);
  border-bottom: 1px solid #e8e8e8;
}


@media (max-width: 768px) {
  .expan-product-code-item-header {
    display: block;
    width: 100%;
    background-color: var(--bg_card);
    border-bottom: 1px solid #e8e8e8;
    padding: 10px 20px;
  }
}

.expan-product-header {
  display: grid;
  height: 40px;
  align-items: center;
  grid-template-columns: 5% 95%;
  padding: 0 20px;
  font-weight: bold;
}

.expan-product-index {
  margin-left: 10px;
}

.inform-text-date {
  display: grid;
  grid-template-columns: 45% 10% 45%;
  align-items: center;
}

.inform-code-date-pickup {
  border-radius: 5px !important;
  height: 40px;
  text-align: right !important;
}

.create-code-btn {
  height: 40px !important;
  width: 100%;
  max-width: 180px;
}

.delete-warranty-code {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: red;
  color: white;
  cursor: pointer;
}

.warranty-code-container {
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: center;
}

.divider-delete {
  text-decoration: line-through;
}

.modal-received-item-title {
  font-weight: bold;
  font-size: 20px;
}

.modal-received-item-content {
  font-size: 16px;
  border-radius: 10px;
}

.order-code {
  font-size: 20px;
  font-weight: normal;
}

.order-status {
  background-color: #ffc107;
  font-size: 11px;
  border-radius: 15px;
  padding: 5px 10px;
  font-weight: bold;
}
 
.order-recived {
  background-color: #28a745 !important;
  color: white;
}

.order-cancel {
  background-color: red !important;
  color: white;
}

.received-modal .ant-modal-content {
  border-radius: 10px !important;
/*  min-width: 520px; */
}

.modal-received-qty {
  display: flex;
  gap: 5px;
}

.modal-received-footer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
}

.validate-btn {
  width: 150px;
  height: 30px !important;
  background: #28a745 !important;
  color: white !important;
  border-radius: 5px;
}

.validate-cancel-btn {
  width: 150px;
  height: 30px !important;
  background: #007bff !important;
  color: white !important;
  border-radius: 5px;
}

.modal-received-change-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 50px;
}

.validate-progress {
  font-size: 18px;
  font-weight: normal;
}

.po-delete-title {
  font-size: 20px;
  font-weight: bold;
}

.po-delete-content {
  /* text-align: center; */
  font-size: 16px;
}

.po-delete-footer {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.delete-po-accept-btn {
  width: 100%;
  height: 30px;
  background: #28a745 !important;
  color: white !important;
  border: none;
}

.delete-po-cancel-btn {
  width: 100%;
  height: 30px;
  background: red !important;
  color: white !important;
  border: none;
}
