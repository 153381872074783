/* Color */
.bg-lightblue {
  background-color: var(--lightblue01);
}
.bg-antiquewhite {
  background-color: antiquewhite;
}
.bg-aliceblue {
  background-color: aliceblue;
}
.bg-ghostwhite {
  background-color: ghostwhite;
}
.bg-ghostwhite {
  background-color: ghostwhite;
}
.bg-row-sub-item {
  background-color: #dadde2;
}
.bg-card-white {
  background-color: #ffffffd9;
}
.bg-card {
  background-color: var(--bg_card);
}
.bg-grayyellow {
  background-color: var(--grayyellow);
}
.bg-lightyellow01 {
  background-color: var(--lightyellow01);
}
.bg-lightblue03 {
  background-color: var(--lightblue03);
}
.bg-lightgray01 {
  background-color: var(--lightgray01);
}
.bg-lightgray02 {
  background-color: var(--lightgray02);
}
.bg-lightgray03 {
  background-color: var(--lightgray03);
}
.bg-lightgray04 {
  background-color: var(--lightgray04);
}
.bg-lightgray05 {
  background-color: var(--lightgray05);
}
.bg-lightgrayblue {
  background-color: var(--lightgrayblue);
}

.color-darkgray01 {
  color: var(--darkgray01);
}
.color-darkgrayblue {
  color: var(--darkgrayblue);
}
.color-darkblue {
  color: var(--darkblue);
}
.color-green {
  color: var(--green);
}
.color-lightgrayblue {
  color: var(--lightgrayblue);
}
.color-lightgray02 {
  color: var(--lightgray02);
}
.color-lightgray03 {
  color: var(--lightgray03);
}
.color-red {
  color: red !important;
}
.color-white {
  color: white;
}
.color-success {
  color: #28a745;
}

/* Font */
.font-larger {
  font-size: larger;
}
.font-size-15px {
  font-size: 15px;
}
.font-size-14px {
  font-size: 14px;
}
.font-size-13px {
  font-size: 13px;
}
.font-size-10px {
  font-size: 10px;
}
.white-space-break {
  white-space: break-spaces;
}
.text-line-through {
  -webkit-text-decoration-line: line-through; /* Safari */
  text-decoration-line: line-through;
}

/* Flex */
.d-flex-1 {
  flex: 1;
}
.d-flex-2 {
  flex: 2;
}
.d-flex-3 {
  flex: 3;
}

/* Col */
.col-p5,
.col-md-p5 {
  flex: 0 0 4.166666665%;
  max-width: 4.166666665%;
}

/* Margin */
.m-t-5 {
  margin-top: 5px;
}
.m-l-5 {
  margin-left: 5px;
}
.m-b-3 {
  margin-bottom: 3px;
}
.m-b-100 {
  margin-bottom: 100px;
}

/* Padding */
.p-l-10px {
  padding-left: 10px;
}
.p-l-15px {
  padding-left: 15px;
}
.p-x-30px {
  padding-left: 30px;
  padding-right: 30px;
}
.p-30px {
  padding: 30px;
}
.p-b-2px {
  padding-bottom: 2px;
}
.p-t-5px {
  padding-top: 5px;
}

.vh-40 {
  height: 40vh;
}
.vh-50 {
  height: 50vh;
}
.vh-55 {
  height: 55vh;
}
.vh-60 {
  height: 60vh;
}
.vh-md-60 {
  height: 60vh;
}
.vh-70 {
  height: 70vh;
}

.max-vh-45 {
  max-height: 45vh;
}
.max-vh-50 {
  max-height: 50vh;
}
.max-vh-55 {
  max-height: 55vh;
}
.max-vh-60 {
  max-height: 60vh;
}

.min-w-130px {
  min-width: 130px;
}

.max-w-350px {
  max-width: 350px;
}

.max-w-500px {
  max-width: 500px;
}

.max-w-100p {
  max-width: 100%;
}

.vw-50 {
  width: 50vw;
}
.vw-60 {
  width: 60vw;
}
.vw-70 {
  width: 70vw;
}

.h-80 {
  height: 80%;
}

.z-index-1 {
  z-index: 1;
}

/* Cursor */
.cursor-pointer {
  cursor: pointer;
}

/* Filter */
.invert-02 {
  filter: invert(0.2);
}
.invert-05 {
  filter: invert(0.5);
}
.invert-07 {
  filter: invert(0.7);
}

/* BTN */
.btn {
  max-height: calc(1.5em + 0.75rem + 2px);
}

/* Line */
.line-1px {
  border-bottom: 1px dashed #e3dfdfab;
}

/* =========== scrollbar (start) =========== */
/* Hide scrollbar */
.div-hide-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.div-hide-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}
::-webkit-scrollbar-track-piece {
  background: white;
}
::-webkit-scrollbar-thumb {
  background: var(--lightgray02);
}
/* =========== scrollbar (end) =========== */

/* Overflow */
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}

/* List */
.list-header {
  background-color: var(--lightgray_opt2);
  min-height: 30px;
  padding-top: 2px;
  border-bottom: 2px solid var(--lightgray02);
}
.list-sub-header {
  background-color: var(--lightgray-opt-01);
}
.list-header.index,
.list-sub-header.index {
  font-weight: bold;
  font-size: 12px;
}
.list-item {
  min-height: 40px;
  padding: 3px 0px;
  border-bottom: 2px solid var(--lightgray01);
}
.list-item.active {
  background-color: var(--black-opt-01);
}
.list-item.index,
.list-item.index input,
.list-item.index textarea {
  font-size: 12px;
}
.list-item.index:nth-child(odd) {
  background-color: var(--white_opt1);
}
.list-item.index input,
.list-item.index textarea {
  border: 1px solid var(--lightgray01);
  margin-top: 3px;
  background-color: unset;
}
.list-item.index input:disabled,
.list-item.index textarea:disabled {
  background-color: #e9ecef;
}
.list-item.index:hover {
  border-bottom: 2px solid var(--lightgray02);
}
.list-item.index:hover input,
.list-item.index:hover textarea {
  border: 1px solid var(--lightgray02);
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .col-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .bg-white-md {
    background-color: white;
  }
  .vh-md-60 {
    height: unset;
  }
}
/* Extra small */
@media (max-width: 576px) {
  .vh-xs-30 {
    height: 30vh;
  }
}
