
.scroll-div {
  overflow-y: auto;
  max-height: 80vh;
}

.RaLayout-root-1 {
  top: -10px
}

.RaLayout-appFrame-2 {
  margin-top: 40px !important;
}

.RaFormInput-input-57 {
  max-width: 350px;
  width: 100%;
}
/* 
.RaMenu-main-34, .RaLayout-appFrame-131, .RaLayout-appFrame-387 {
  margin-top: 0px !important;
}

.MuiToolbar-dense {
  min-height: 60px !important;
} */

/* Array input */
#contract_responsible_persons .RaSimpleFormIterator-root-71,
#provinces_districts .RaSimpleFormIterator-root-86,
#contract_item_categories .RaSimpleFormIterator-root-71,
#contract_item_categories ul[class^="RaSimpleFormIterator-root-"] {
  margin-top: 10px;
}

.MuiDrawer-root a[href^='#/item_fees'],
.MuiDrawer-root a[href^='#/item_discounts'],
.MuiDrawer-root a[href^='#/item_category_fees'],
.MuiDrawer-root a[href^='#/item_category_fees'],
.MuiDrawer-root a[href^='#/contract_item_categories'],
.MuiDrawer-root a[href^='#/contract_items']
 {
  display: none;
}

.MuiFormGroup-root .MuiFormHelperText-root {
  display: none;
}

#item_category_id-helper-text, #dst_province_id-helper-text, #src_province_id-helper-text,
#province_id-helper-text, #district_id-helper-text, #item_id-helper-text, #vendor_id-helper-text {
  height: 0px;
}
.regional-vat{
  margin-left: 18px !important;
  width: 256px !important;

 } 
 .regional-price{
  width: 500px !important;
 }
 .regional-form{
  width: 700px !important;
 }
@media only screen and (max-width: 800px) {
 .regional-vat{
  margin-left: 0px !important;
  width: 256px !important;

 }
 .regional-price{
  width: 256px !important;
 }
 
}