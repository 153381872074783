@import url("https://cdn.jsdelivr.net/npm/@mdi/font@6.x/css/materialdesignicons.min.css");
 
.mdi-size{ 
 font-size: 23px;
 font-weight: bold;
  width: 20px;
  height: 20px;
  margin-top:-15px;
}

.icon-setting {
  background: url("../assets/images/icon_setting.png") no-repeat;
  width: 20px;
  height: 20px;
  background-size: cover;
  display: inline-block;
  background-color: transparent;
  margin: auto;
}

.icon-home {
  background: url("../assets/images/icon_home.svg") no-repeat;
  width: 20px;
  height: 20px;
  background-size: cover;
  display: inline-block;
  background-color: transparent;
}

.icon-quotation {
  background: url("../assets/images/icon_quotation.svg") no-repeat;
  width: 20px;
  height: 20px;
  background-size: cover;
  display: inline-block;
  background-color: transparent;
}

.icon-contact {
  background: url("../assets/images/icon_contact.svg") no-repeat;
  width: 20px;
  height: 20px;
  background-size: cover;
  display: inline-block;
  background-color: transparent;
}

.icon-report-tracking-order {
  background: url("../assets/images/icon_report_tracking_order.svg") no-repeat;
  width: 20px;
  height: 20px;
  background-size: cover;
  display: inline-block;
  background-color: transparent;
}

.icon-order {
  background: url("../assets/images/icon_order.svg") no-repeat;
  width: 20px;
  height: 20px;
  background-size: cover;
  display: inline-block;
  background-color: transparent;
}

.icon-billing {
  background: url("../assets/images/icon_billing.svg") no-repeat;
  width: 20px;
  height: 20px;
  background-size: cover;
  display: inline-block;
  background-color: transparent;
}

.icon-report-vendor {
  background: url("../assets/images/icon_list_alt.svg") no-repeat;
  width: 20px;
  height: 20px;
  background-size: cover;
  display: inline-block;
  background-color: transparent;
}

.icon-mr {
  background: url("../assets/images/icon_mr.svg") no-repeat;
  width: 20px;
  height: 20px;
  background-size: cover;
  display: inline-block;
  background-color: transparent;
}

.icon-warehouse {
  background: url("../assets/images/icon_warehouse.svg") no-repeat;
  width: 20px;
  height: 20px;
  background-size: cover;
  display: inline-block;
  background-color: transparent;
}

.icon-inventory {
  background: url("../assets/images/icon-inventory.svg") no-repeat;
  width: 20px;
  height: 20px;
  background-size: cover;
  display: inline-block;
  background-color: transparent;
}

.icon-profile {
  background: url("../assets/images/icon_profile.svg") no-repeat;
  width: 25px;
  height: 23px;
  background-size: cover;
  display: inline-block;
  background-color: transparent;
  margin: auto;
  margin-left: 5px;
  margin-bottom: 5px;
}

.icon-admin {
  background: url("../assets/images/icon_admin.svg") no-repeat;
  width: 20px;
  height: 21px;
  background-size: cover;
  display: inline-block;
  background-color: transparent;
  margin: auto;
  margin-left: 5px;
}

.icon-export {
  background: url("../assets/images/icon_export.png") no-repeat;
  width: 25px;
  height: 25px;
  background-size: cover;
  display: inline-block;
  background-color: transparent;
  margin: auto;
  margin-left: 5px;
}

.icon-edit {
  background: url("../assets/images/icon_edit.svg") no-repeat;
  width: 20px;
  height: 20px;
  background-size: cover;
  display: inline-block;
  background-color: transparent;
}

.icon-open-new {
  background: url("../assets/images/icon_open_new.svg") no-repeat;
  width: 15px;
  height: 15px;
  background-size: cover;
  display: inline-block;
  background-color: transparent;
}

.icon-import {
  background: url("../assets/images/icon_import.png") no-repeat;
  width: 25px;
  height: 25px;
  background-size: cover;
  display: inline-block;
  background-color: transparent;
  margin: auto;
  margin-left: 5px;
}

.icon-checkmark {
  background: url("../assets/images/icon_checkmark.png") no-repeat;
  width: 20px;
  height: 20px;
  background-size: cover;
  display: inline-block;
  background-color: transparent;
  margin: auto;
  padding-top: 5px;
}

.sidelink.is-active .icon-admin {
  background: url("../assets/images/icon_link_dropdown.png") no-repeat;
  width: 20px;
  height: 21px;
  background-size: cover;
  display: inline-block;
  background-color: transparent;
  margin: auto;
  margin-left: 5px;
}

.icon-adjust {
  background: url("../assets/images/icon_adjust.png") no-repeat;
  width: 30px;
  height: 30px;
  background-size: cover;
  display: inline-block;
  background-color: transparent;
  margin: auto;
  margin-left: 5px;
}

.icon-info {
  background: url("../assets/images/icon_info.svg") no-repeat;
  width: 20px;
  height: 20px;
  background-size: cover;
  display: inline-block;
  background-color: transparent;
}
.icon-info.large {
  width: 60px;
  height: 60px;
}

.icon-folder-basket {
  background: url("../assets/images/icon_folder_phc.svg") no-repeat;
  width: 60px;
  height: 60px;
  background-size: cover;
  display: inline-block;
  background-color: transparent;
}

.icon-folder-basket-station {
  background: url("../assets/images/icon_folder_station.svg") no-repeat;
  width: 60px;
  height: 60px;
  background-size: cover;
  display: inline-block;
  background-color: transparent;
}

.icon-fullscreen-white {
  background: url("../assets/images/icon_fullscreen.svg") no-repeat;
  width: 20px;
  height: 20px;
  background-size: cover;
  display: inline-block;
  background-color: transparent;
  filter: invert(1);
}

.icon-arrow-up {
  background: url("../assets/images/icon_up.svg") no-repeat;
  width: 20px;
  height: 10px;
  background-size: cover;
  display: inline-block;
  background-color: transparent;
  margin: auto;
  margin-left: 3px;
  cursor: pointer;
}
.icon-arrow-down {
  background: url("../assets/images/icon_down.svg") no-repeat;
  width: 20px;
  height: 10px;
  background-size: cover;
  display: inline-block;
  background-color: transparent;
  margin: auto;
  margin-left: 3px;
  cursor: pointer;
}

.icon-arrow-up.active,
.icon-arrow-down.active {
  border-radius: 4px;
  background-color: #e3e4e8;
}

.icon-setting .dropdown-toggle::after {
  border: none;
}

.icon-show-more {
  background: url("../assets/images/icon_show_more.png") no-repeat;
  width: 20px;
  height: 20px;
  background-size: cover;
  display: inline-block;
  background-color: transparent;
  cursor: pointer;
  margin-top: 2px;
}

.icon-show-less {
  background: url("../assets/images/icon_show_less.png") no-repeat;
  width: 20px;
  height: 20px;
  background-size: cover;
  display: inline-block;
  background-color: transparent;
  cursor: pointer;
  margin-top: 3px;
}

.icon-cancel {
  background: url("../assets/images/icon_cancel.svg") no-repeat;
  width: 30px;
  height: 30px;
  background-size: cover;
  display: inline-block;
  background-color: transparent;
  cursor: pointer;
  margin-top: 3px;
  margin-left: -30px;
}

.icon-error {
  background: url("../assets/images/icon_error.svg") no-repeat;
  width: 15px;
  height: 15px;
  background-size: cover;
  display: inline-block;
  background-color: transparent;
  cursor: pointer;
  margin-top: 1px;
}

.div-icon-show {
  height: 25px;
  width: 100%;
  background-color: var(--lightgrayblue);
  border-radius: 4px;
}

.plus-button {
  color: white;
  border: 2px solid rgb(255, 255, 255);
  background-color: var(--bootstrap_green);
  font-size: 16px;
  height: 2.5em;
  width: 2.5em;
  border-radius: 50% !important;
  position: relative;
  z-index: 100;
}

.plus-button:after,
.plus-button:before {
  content: "";
  display: block;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.plus-button:before {
  height: 1em;
  width: 0.2em;
}

.plus-button:after {
  height: 0.2em;
  width: 1em;
}

.plus-button.small {
  font-size: 12px;
}

.plus-button.large {
  font-size: 22px;
}

/* Sign */
.sign-baht:after {
  padding-left: 3px;
  content: "฿";
}

.sign-is-required:after {
  padding-left: 3px;
  color: red;
  content: "*";
}

/* Exclamation mark */
.exclamation-mark {
  cursor: pointer;
  border-radius: 50%;
  color: white;
  background-color: red;
  width: 20px;
  height: 20px;
  padding-left: 8px;
  margin-left: 5px;
  line-height: 19px;
}

/*************** Symbols ***************/
.symbol-checkmark::after {
  content: "\2713";
}
/*************** Symbols ***************/

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .icon-adjust {
    width: 20px;
    height: 20px;
  }
}
