.approved-container {
  display: flex;
  cursor: pointer;
  transition: 0.25s;
}

.approved-text {
  display: flex;
}

.cancel-text {
  display: none;
}

.approved-container:hover .approved-text {
  display: none;
}

.duplicate-modal-table {
  display: grid;
  gap: 5px;
  grid-template-columns: 10% 70% 20%;
  margin-bottom: 15px;
  align-items: center;

}

.display-sub-item-container {
  display: grid;
  grid-template-columns: 5% 28% 7% 7% 15% 8% 10% 15%;
  gap:5px;
  background-color: white;
  margin-left: 15px;
  margin-right: 5px;
  align-items: center;
  border-radius: 5px;
  padding: 10px 0px 10px 10px;
  text-align: center;
}

.disabled-tabs {
  cursor: not-allowed;
  background-color: #bebebe;
}

.disabled-tabs > a {
  color: rgba(0, 0, 0, 0.5) !important;
}

.display-item-container {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 5% 28% 8% 6% 14% 9% 10% 15%;
  gap:5px;
  background-color: white;
  margin-left: -15px;
  margin-right: -15px;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
}



.display-expense-container {
  display: grid;
  grid-template-columns: 5% 28% 8% 6% 23% 10% 15%;
  gap:5px;
  background-color: white;
  margin-left: -15px;
  margin-right: -15px;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
}

.approved-container:hover .cancel-text {
  display: block;
}

.is_parent_item {
  max-height: 32px;
  overflow: hidden;

}

.is_parent_item-open {
  max-height: unset;
}

.search-template-btn {
  background-color: #007bff;
  padding: 5px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: white;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  transition: 0.15s;
}

.search-template-btn:hover {
  transition: 0.15s;
  transform: scale(0.95);
}

.add-to-template-btn {
  background-color: yellow;
  color: black;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: 0.15s;
}

.cancel-template-btn {
  background-color: unset !important;
  border: 2px solid red !important;
  /* padding: 10px 5px; */
  color:red !important;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  width: 100%;

  transition: 0.15s;
}


.cancel-template-btn:hover {
  background-color: unset;
  border: 2px solid red;
  /* padding: 10px 5px; */
  color:red;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;

  transform: scale(0.95);
  transition: 0.15s;
}

.save-template-btn:hover {
  background-color: green !important;
  /* padding: 10px 5px; */
  color:white !important;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.select-template-btn {
  background-color: green;
  padding: 5px 10px;
  color: white;
  border-radius: 5px;
  transition: 0.15s;
  position: relative;
  z-index: 999;

  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.select-template-btn:hover {
  transform: scale(0.95);
  transition: 0.15s;
}

.save-template-btn {
  background-color: green !important;
  /* padding: 10px 5px; */
  color:white !important;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  width: 100%;
}

.add-to-template-btn:hover {
  transition: 0.15s;
  transform: scale(0.95);
  color:rgba(0, 0, 0, 0.5);
}

.select-from-template-btn {
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: 0.15s;

  text-align: center;
}

.select-from-template-btn:hover {
  transition: 0.15s;
  transform: scale(0.99);
  color:rgba(255, 255, 255, 0.5);
}


.approved-container:hover {
  background-color: red !important;
  transition: 0.25s;
}

.witholding-tax {
  width: 100%;
  display: flex !important;
  justify-content: right !important;
}

.witholding-tax > div {
  width: 75% !important;
}

.item-vendor-and-dc-tabs {
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.use-price-text {
  font-weight: bold;
  font-size: 14px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #00000080;
}

/*.use-price-type::before {
  width: 15px;
  height: 15px;
  content: '';
  display: inline-block;
  background-color: #007bff;
  border-radius: 50%;
  margin-right: 5px;
}*/

.use-price-type {
  color: #007bff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 990px) {
  .item-vendor-and-dc-tabs {
    flex-direction: column !important;
    align-items: unset;
    justify-content: unset;
  }

  .use-price-text {
    margin-top: 10px;
  }

  .select-from-template-btn {
    margin-top: 10px !important;
  }
}

@media only screen and (max-width: 767px) {
  .display-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .display-item-container {
    display: none;
  }

  .display-sub-item-container {
    display: none;
  }

  .display-item-container-main {
    display: none;
  }

 
}


