body {
  margin: 0;
  font-family: Jost, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--lightblue03);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.nav-dropdown-right .dropdown-menu {
  right: 0;
  left: auto;
}

.sidebar {
  min-width: 250px;
}

.icon-menu {
  width: 40px;
  height: 40px;
  z-index: 0;
  cursor: pointer;
}

.background-header {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  user-select: none;
}

.background-header.top-layer {
  z-index: -5;
}

.background-header.bottom-layer {
  z-index: -10;
}

.sidelink,
.sidelink-title {
  margin: 5px;
  margin-left: 15px;
  padding: 5px;
  border-radius: 5px;
  color: gray;
}

.sidelink.is-active {
  background-color: var(--lightblue03);
  color: black;
}
.sidelink:hover {
  color: black;
  text-decoration: none;
  background-color: var(--lightblue02);
}
.sidelink.is-active i {
  filter: invert(0);
}

.sidelink.sublink:not(.is-active) {
  visibility: hidden;
}
.sidelink.sublink {
  visibility: visible;
  padding-left: 45px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: smaller;
}
.sidelink-title {
  cursor: pointer;
  padding-bottom: 0px;
  margin-bottom: 0px;
  border-radius: 0px;
  font-size: small;
  border-top: 1px solid var(--lightgray01);
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.sidelink-title:hover {
  filter: invert(0.5);
  color: black;
}

.tab-responsive {
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
.tab-responsive > li {
  float: none;
  display: inline-block;
}
.tab-responsive > li > a {
  display: inline-block;
  text-decoration: none;
}

.link-no-hover {
  color: black;
}
.link-no-hover:hover {
  border: 1px solid var(--lightgrayblue);
  text-decoration: none;
  background-color: var(--index-on-hover) !important;
}

.nav-select-wrapper {
  width: 100%;
}

.app-filter-tag {
  margin-left: 10px;
  margin-top: 21px;
  padding-top: 5px;
  height: 20px;
}

.app-filter-tag-md {
  margin-bottom: 10px;
  padding-top: 5px;
  height: 20px;
}

.version-text {
  position: fixed;
  bottom: 5px;
  left: 5px;
  color: var(--darkgrayblue);
  font-size: 9px;
}

/* pagination (start) */
.pagination {
  margin-bottom: 0px;
  flex-wrap: wrap;
}

.pagination a {
  min-width: 15px;
  margin: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  color: var(--darkblue) !important;
}
.pagination__link--active a {
  background: var(--darkblue);
  color: white !important;
}
.pagination__link--disabled a {
  color: var(--lightgrayblue) !important;
  cursor: default;
}
/* pagination (end) */

.signout-btn-wrapper .button {
  height: 30px !important;
}
#signout-btn button {
  height: 30px !important;
}

.date-picker-wrapper,
.date-picker-wrapper > div.react-datepicker-wrapper,
.date-picker-wrapper > div > div.react-datepicker__input-container .date-picker-wrapper > div > div.react-datepicker__input-container input {
  width: 100%;
}

/* Amplify */
.Toast__toast___XXr3v {
  display: none;
}

.new-line {
  white-space: pre-line;
}

/* sm */
@media (max-width: 575px) {
  .header-title {
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 1.1;
  }

  .col-custom-sm-100 {
    display: flex;
    width: 100% !important;
  }

  .col-custom-sm-50 {
    display: flex;
    width: 50% !important;
    /* background-color: skyblue; */
  }

  .col-custom-sm-30 {
    display: flex;
    width: 30% !important;
    /* background-color: yellow; */
  }

  .col-custom-sm-20 {
    display: flex;
    width: 20% !important;
    /* background-color: pink; */
  }

  .flex-custom-sm {
    display: flex;
  }

  .flex-custom-sm-none {
    display: none;
  }

  .justify-custom-sm-right {
    display: flex;
    justify-content: flex-end;
  }

  .modalstyle-dc .ant-modal-content {
    width: calc(100vw - 20px);
    max-width: 376px;
    padding-top: 8px;
    padding-bottom: 8px;
    /* background-color: pink; */
  }
}

/* md */
@media (min-width: 576px) {
  .header-title {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.1;
  }

  .col-custom-80 {
    display: flex;
    width: 80% !important;
  }

  .col-custom-40 {
    display: flex;
    width: 40%;
  }

  .col-custom-30 {
    display: flex;
    width: 30%;
  }

  .col-custom-26 {
    display: flex;
    width: 26%;
    /* background-color: pink; */
  }

  .col-custom-22 {
    display: flex;
    width: 22%;
  }

  .col-custom-20 {
    /* display: flex; */
    width: 20%;
  }

  .col-custom-18 {
    display: flex;
    width: 18%;
  }

  .col-custom-14 {
    display: flex;
    width: 14%;
  }

  .col-custom-12 {
    display: flex;
    width: 14%;
  }

  .col-custom-10 {
    display: flex;
    width: 10%;
  }

  .col-custom-4 {
    display: flex;
    width: 4%;
  }

  .flex-custom-md {
    display: flex;
  }

  .flex-custom-md-none {
    display: none;
  }

  .justify-custom-center {
    display: flex;
    justify-content: center;
  }

  .display-md-none {
    display: none;
  }

  .modalstyle-dc .ant-modal-content {
    width: calc(100vw - 200px);
    max-width: 650px;
    padding: 8px;
    /* background-color: greenyellow; */
  }
}

/* large and over */
@media (min-width: 768px) {
}

.col-break-word {
  width: 100%;
  word-wrap: break-word;
  word-break: break-word;
}

.col-ellipsis {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.date-picker-w-100 {
  width: 100%;
}

.tooltip-flex {
  display: flex;
}

.color-active {
  color: #28a745;
}

.color-reserved {
  color: #dc3545;
}

.color-delivered {
  color: #0f205e;
}

.color-delete {
  color: #000000;
}

.color-inactive {
  color: #000000;
}

.text-custom-18 {
  font-size: 18px;
}

.border-radious-custom {
  border-radius: 5px;
}

.running-number-highlight {
  padding: 1px 5px;
  border-radius: 10px;
  background-color: #ebebebab !important;
}

.border-first {
  border-radius: 5px 5px 0px 0px;
}

.border-last {
  border-radius: 0px 0px 5px 5px;
  /* background: orange; */
}

.text-custom-mw {
  max-width: 60px;
}

.item-custom-header {
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  min-height: calc(1.5em + 0.75rem + 2px);
}

.bg-lightred {
  background-color: var(--lightred) !important;
}

.select-custom-content {
  display: flex;
  flex-direction: column;
}

.ant-select-selection-item {
  font-size: 14px !important;
}


:root {
  --tmr-bootstrap-border-color: #dee2e6;
  --tmr-white: #eee;
  --tmr-table-header: #54667a;
  --tmr-row-divider-color: #3490dc;
  --tmr-stripped-row-background-color: rgba(0, 0, 0, 0.05);
}

/*-- ==============================================================
Screen smaller than 760px and iPads.
============================================================== */

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
  
  [data-content]:before {
      content: attr(data-content);
  }
  
  /* Force table to not be like tables anymore */
  .table-mobile-responsive,
  .table-mobile-responsive thead,
  .table-mobile-responsive tbody,
  .table-mobile-responsive th,
  .table-mobile-responsive td,
  .table-mobile-responsive tr {
      display: block;
  }

  .table-mobile-responsive.text-center {
      text-align: left !important;
  }
  .table-mobile-responsive caption {
      width: max-content;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .table-mobile-responsive thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
  }

  .table-mobile-responsive> :not(:first-child) {
      border-top: none;
  }

  .table-mobile-responsive>:not(caption)>*>* {
      border-color: var(--tmr-bootstrap-border-color);
  }

  .table-mobile-responsive tr:not(.bg-light-blue) {
      border-bottom: 2px solid var(--tmr-row-divider-color);
  }

  /* Default layout */
  .table-mobile-responsive td {
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid var(--tmr-white);
      position: relative;
      padding-left: 50%;
      padding-top: 1.5rem !important;
  }

  .table-mobile-responsive td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 0;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      font-weight: bold;
      color: var(--tmr-table-header);
  }
  
  /* Sided layout */
  .table-mobile-responsive.table-mobile-sided> :not(:first-child) {
      border-top: none;
  }

  .table-mobile-responsive.table-mobile-sided>:not(caption)>*>* {
      border-color: var(--bs-table-border-color);
  }
  .table-mobile-responsive.table-mobile-sided td {
        /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid var(--tmr-white);
      position: relative;
      padding-left: 50%;
      padding-top: 0px !important;
      display: flex;
      justify-content: flex-end;
  }
  .table-mobile-responsive.table-mobile-sided td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 0;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      font-weight: bold;
      color: var(--tmr-table-header);
  }

  /* Styleless */
  .table-mobile-responsive.table-mobile-styleless tr:not(.bg-light-blue) {
      border-bottom: none !important;
  }

  /* Stripped rows */
  .table-mobile-responsive.table-mobile-striped>tbody>tr:nth-of-type(odd)>* {
      background-color: var(--tmr-stripped-row-background-color) !important;
  }
}

.status-green{
  color: #28a745;
  font-size: 24px;
}

 .mobile-800-none {
  display: block;
 }

@media only screen and (max-width: 990px) {
  .mobile-800-none {
    display: none!important;
  }
}


.mobile-800-view {
  display: none;
 }

@media only screen and (max-width: 990px) {
  .mobile-800-view {
    display: block!important;
  }
}