.nav-link.active {
  background-color: rgb(196 217 234 / 44%) !important;
  border-color: rgb(244 250 255 / 0%) !important;
}

.nav-tabs {
  border-bottom: 1px solid #ffffffab;
}

.nav-tabs .nav-item {
  border: 1px solid #bebebe59;
  border-radius: 10px 10px 0px 0px;
}

.nav-item:hover {
  border-color: #ffffffab !important;
  border-radius: 10px 10px 0px 0px;
}

.quo-scrollable-table {
  max-height: calc(100vh - 120px);
  overflow-y: scroll;
}

.quo-table {
  margin-top: 10px;
}


.quo-table input,
.quo-table .table-item-bg,
.quo-right-side-bar,
.quo-right-side-bar input {
  font-size: 15px;
}

.quo-right-side-bar {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 10px;
}

.quo-btn-add-item-wrapper {
  position: fixed;
  z-index: 100;
  right: 20px;
  bottom: 20px;
  margin-left: 10px;
  height: 15px;
}
.quo-btn-add-item {
  top: -55px;
}

.quo-table-h {
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
}

.table-header .table-item-bg {
  background-color: rgb(255 255 255 / 70%);
  font-size: 15px;
}

.sub-row-item-bg {
  background-color: #f5f5f5;
  border-color: #dadde2;
  font-size: 13px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

.row-discount .table-item-bg,
.row-discount input {
  color: red;
}

.table-item-bg {
  display: flex;
  align-items: center;
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
  min-height: calc(1.5em + 0.75rem + 2px);
}

.table-item-bg.category-title {
  background-color: var(--lightgrayblue);
}

.purchase-price .table-item-bg.category-title {
  background-color: var(--lightred);
}

.table-item-bg.category-total {
  background-color: #ffffff00;
  font-weight: bold;
}

.category-title input {
  background-color: #e7f1fe7a;
  border: none;
  margin-left: 7px;
  font-weight: bold;
}

.table-item-bg.total {
  background-color: var(--grayyellow);
  font-weight: bold;
  font-size: larger;
}

.table-item-bg.first {
  margin-left: 0px;
}

.table-item-hoverable-wrapper {
  cursor: pointer;
}
.table-item-hoverable-wrapper:hover .table-item-bg {
  background-color: var(--lightgray01);
  text-decoration: none;
}
.table-item-hoverable-wrapper.selected .table-item-bg {
  background-color: var(--lightgray01);
  text-decoration: none;
}

.table-disable .table-item-bg {
  background-color: var(--lightgray01);
  color: var(--lightgray03);
}
.table-disable .category-title {
  background-color: var(--lightgrayblue02);
}

.div-scrollable {
  overflow-y: scroll;
  max-height: 70vh;
}

.quo-state-tag {
  margin-left: 5px;
  margin-right: 5px;
  height: 20px;
}

.card-box .quo-data-collapse {
  display: flex;
  flex-direction: column;
  max-height: 0px;
  font-size: smaller;
  /* transition: max-height 700ms linear; */
  overflow-y: scroll;
  overflow-x: hidden;
  border-top: 1px dashed #e3dfdfab;
  padding-top: 5px;
  color: var(--lightgray03);
  /* background-color: #fffce9; */
}

.card-box .quo-data-collapse.show {
  padding-bottom: 15px;
  max-height: 250px;
  height: auto;
}

.card-box .quo-data-collapse:hover {
  /* background-color: white; */
  background-color: var(--index-on-hover) !important;
}

.disabled-pale-text {
  color: gray;
}

/* Large devices */
@media (max-width: 1199px) {
  .quo-scrollable-table {
    max-height: calc(100vh - 160px);
    overflow-y: scroll;
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .quo-scrollable-table {
    max-height: calc(100vh - 186px);
    overflow-y: scroll;
  }
}
